
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 400;
  text-size-adjust: none;
  font-family: system-ui;
  color: #2d3748;
  height: 100%;
}

html {
  box-sizing: border-box;
  border-collapse: collapse;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

* { border-collapse: inherit; }

a {
  color: inherit;
}

.viewport {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 66.68rem;
  height: 100%;
  padding: 0 1em;
  margin: 0 auto;
}

.header,
.footer {
  flex: none;
}

.header {
  display: flex;
  align-items: center;
  font-size: 1em;
  margin: 1.5rem 0;
}

@media all and (max-width: 25em) {
  .header {
    flex-direction: column;
  }

  .logo {
    margin-bottom: .75em;
  }
}

@media all and (min-width: 25.01em) {
  .header {
    justify-content: space-between;
  }
}

main {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
}

.linkHomepage {
  font-size: 1.25em;
}

article {
  line-height: 1.625;
}

.welcome {
  font-size: 3rem;
  color: #000;
}

.profil {
  font-size: 1.655rem;
  margin-top: 1.5rem;
}

.wrapperLink {
  margin: 1.5rem 0;
  border-top: solid 1px #2d3748;
  padding-top: 1rem;
  display: flex;
  justify-content: center;
}

.link {
  font-size: inherit;
}

.link + .link {
  margin-left: 1rem;
}

.blog {
  font-size: 1.25rem;
  margin-top: 2rem;
}

.time {
  font-size: .625rem;
}

.changelog {
  font-size: .75rem;
  margin-top: 1rem;
}

.changelog ul {
  margin-top: 0;
}
